import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
export const _frontmatter = {
  "product": "verify",
  "category": "Guides & Tools",
  "sort": 0,
  "title": "FAQ",
  "subtitle": "Common issues and questions when using Criipto Verify"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Unknown Access Code error`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`Unknown Access Code`}</inlineCode>{` error can happen for one of two reasons when trying to fetch information from the `}<inlineCode parentName="p">{`userinfo`}</inlineCode>{` endpoint.`}</p>
    <ol>
      <li parentName="ol">{`You are reusing a code, codes can only be used once.`}</li>
      <li parentName="ol">{`Your application OIDC setting `}<inlineCode parentName="li">{`User info response strategy`}</inlineCode>{` is set to `}<inlineCode parentName="li">{`fromTokenEndpoint`}</inlineCode>{`.`}</li>
    </ol>
    <p>{`If you are seeing this error in production but it worked in test, make sure that your production application and test application have the exact same OIDC settings.`}</p>
    <p>{`You can use the copy application feature from test to production to ensure the settings are the same.`}</p>
    <h2>{`MitID Core Client error`}</h2>
    <p>{`If you encounter the `}<inlineCode parentName="p">{`mitid_core_client_error`}</inlineCode>{` while running test logins, it indicates issues with the MitID PP environment. `}</p>
    <p>{`Please note that the MitID PP environment may occasionally experience instability, and MitID does not provide SLAs or status updates for this environment. Criipto does not have control over the MitID Test Tools.`}</p>
    <h3>{`Using TOTP test user as a workaround`}</h3>
    <p>{`In some cases, only App Simulator test users may be affected. If you are experiencing issues with the App Simulator (e.g. the `}<em parentName="p">{`Scan QR and Confirm`}</em>{` action is not available), you can try `}<a parentName="p" {...{
        "href": "/verify/e-ids/danish-mitid/#test-users"
      }}>{`creating a test user`}</a>{` with a TOTP authenticator instead. You can do this by checking both the `}<em parentName="p">{`Password`}</em>{` and the `}<em parentName="p">{`TOTP simulator`}</em>{` checkboxes at user creation time. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      